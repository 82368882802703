<template>
  <v-card>
    <v-card-subtitle>
      <strong>Catalog Details</strong>
    </v-card-subtitle>
    <v-row>
      <card-list-item title="Name">
        {{ catalog.name | filterNA }}
      </card-list-item>
      <card-list-item title="Unit of Measure">
        {{ catalog.unit_of_measure }}
      </card-list-item>
    </v-row>
    <v-row>
      <card-list-item title="Description">
        {{ catalog.description | filterNA }}
      </card-list-item>
      <card-list-item title="Material Type">
        {{ formatType(catalog.material_type) }}
      </card-list-item>
    </v-row>
    <card-list-item
      title="Price"
      v-if="catalog.material_type !== 'CV'"
    >
      {{ catalog.price | filterNA | dollarSign }}
    </card-list-item>
    <v-btn
      v-if="$store.getters.can('update-catalog')"
      dark
      absolute
      fab
      bottom
      right
      @click="editDialog = !editDialog"
    >
      <v-icon>mdi-pencil-outline</v-icon>
    </v-btn>
    <catalog-edit
      v-model="editDialog"
      :catalog="catalog"
    />
  </v-card>
</template>

<script>
import CatalogEdit from './CatalogEdit'

export default {
  name: 'CatalogSection',
  components: { CatalogEdit },
  props: {
    catalog: { type: Object, required: true },
    catalogTypes: { type: Array, required: true }
  },
  data: () => ({
    editDialog: false
  }),
  methods: {
    formatType (type) {
      return this.getTextGivenValue(type, this.catalogTypes)
    }
  }
}
</script>
