<template>
  <v-card>
    <v-card-subtitle>
      <strong>Converter Details</strong>
    </v-card-subtitle>
    <v-card-text>
      <p class="body-2 mb-0">
        Static Image Set
      </p>
      <v-row v-if="staticPictureSet.length">
        <v-col
          v-for="image in staticPictureSet"
          :key="image.id"
          cols="12"
          md="4"
        >
          <a
            :href="image.image"
            target="_blank"
          >
            <v-img
              :src="image.image"
              max-height="400px"
              max-width="400px"
            />
          </a>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <p class="body-2">
            No static picture set attached.
          </p>
        </v-col>
      </v-row>
      <p class="body-2 mb-0">
        Video Image Set
      </p>
      <v-row v-if="catalog.converter && catalog.converter.picture_set">
        <v-col>
          <p class="body-2">
            Image Group {{ catalog.converter.picture_set.name }}
          </p>
          <v-sheet
            max-height="400"
            max-width="400"
          >
            <converter-image-viewer
              :photo-group="catalog.converter.picture_set"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col>
          <p class="body-2">
            No video picture set attached.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <card-list-item
          no-cols
          title="Code"
        >
          {{ catalog.converter.code | filterNA }}
        </card-list-item>
      </v-row>
      <v-row>
        <card-list-item
          title="Cat Name"
          no-cols
        >
          {{ catalog.converter.cat_name | filterNA }}
        </card-list-item>
      </v-row>
      <v-row>
        <card-list-item
          title="Notes"
          no-cols
        >
          {{ catalog.converter.notes | filterNA }}
        </card-list-item>
      </v-row>
      <v-row>
        <card-list-item
          title="Converter Group"
          no-cols
        >
          {{ catalog.converter.converter_group_name | filterNA }}
        </card-list-item>
      </v-row>
      Make Models
      <v-data-table
        v-if="catalog.converter.make_models.length > 0"
        class="mx-4"
        :headers="makeModelTableHeaders"
        :items="catalog.converter.make_models"
        :mobile-breakpoint="400"
      />
      <v-row v-else>
        <v-col>
          <p class="body-2">
            Not attached to any make/models.
          </p>
        </v-col>
      </v-row>
      Vehicles
      <v-data-table
        v-if="vehicles.length > 0"
        class="mx-4"
        :headers="vehicleTableHeaders"
        :items="vehicles"
        :mobile-breakpoint="400"
      />
      <v-row v-else>
        <v-col>
          <p class="body-2">
            Not attached to any vehicles.
          </p>
        </v-col>
      </v-row>
      <v-row v-if="price">
        <card-list-item
          title="Price"
          no-cols
        >
          <span v-if="price">
            $ {{ price.price | toFixed(2) }}
          </span>
        </card-list-item>
        <card-list-item
          title="o2 Sensor"
          no-cols
        >
          {{ catalog.converter.o2_sensor || '--' }}
        </card-list-item>
        <card-list-item
          title="Height"
          no-cols
        >
          {{ catalog.converter.height }}
        </card-list-item>
        <card-list-item
          title="Width"
          no-cols
        >
          {{ catalog.converter.width }}
        </card-list-item>
        <card-list-item
          title="Catalyst Weight"
          no-cols
        >
          {{ catalog.converter.catalyst_weight }} kg
        </card-list-item>
        <card-list-item
          title="Debris Weight"
          no-cols
        >
          {{ catalog.converter.debris_weight }} kg
        </card-list-item>
        <card-list-item
          title="Empty Shell Weight"
          no-cols
        >
          {{ catalog.converter.empty_shell_weight }} kg
        </card-list-item>
        <card-list-item
          title="Whole Weight"
          no-cols
        >
          {{ catalog.converter.whole_weight }} kg
        </card-list-item>
      </v-row>
      <v-row>
        <card-list-item
          v-if="$store.getters.can('view-assays')"
          title="Average Yield Pounds"
          no-cols
        >
          {{ averageAssayValue('yield_lbs') | filterNA | toFixed(4) | trimDecimals | addCommas | appendLbs }}
        </card-list-item>
      </v-row>
      <v-list-item v-if="$store.getters.can('view-assays')">
        <v-list-item-content>
          <v-list-item-title class="text-center body-1 grey--text text--darken-2">
            Average PPM
          </v-list-item-title>
          <v-row class="text-center">
            <v-col class="pt-color">
              <v-list-item-title class="caption">
                Platinum
              </v-list-item-title>
              {{ averageAssayValue('pt_ppm') | toFixed(4) | trimDecimals | addCommas | appendPpm }}
            </v-col>
            <v-col class="pd-color">
              <v-list-item-title class="caption">
                Palladium
              </v-list-item-title>
              {{ averageAssayValue('pd_ppm') | toFixed(4) | trimDecimals | addCommas | appendPpm }}
            </v-col>
            <v-col class="rh-color">
              <v-list-item-title class="caption">
                Rhodium
              </v-list-item-title>
              {{ averageAssayValue('rh_ppm') | toFixed(4) | trimDecimals | addCommas | appendPpm }}
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>
    <v-btn
      v-if="$store.getters.can('update-catalog', 'edit-cat-name')"
      dark
      absolute
      fab
      bottom
      right
      @click="editDialog = !editDialog"
    >
      <v-icon>mdi-pencil-outline</v-icon>
    </v-btn>
    <converter-edit
      v-model="editDialog"
      :converter="catalog.converter"
    />
  </v-card>
</template>

<script>
import ConverterEdit from './ConverterEdit'
import ConverterImageViewer from '@/base/components/ConverterImageViewer'
import axios from 'axios'

export default {
  name: 'ConverterSection',
  components: {
    ConverterEdit,
    ConverterImageViewer
  },
  props: {
    assays: { type: Array, required: false, default: null },
    catalog: { type: Object, required: true }
  },
  data: () => ({
    editDialog: false,
    makeModelTableHeaders: [
      { text: 'Make', value: 'make' },
      { text: 'Model', value: 'model' }
    ],
    vehicleTableHeaders: [
      { text: 'Make', value: 'make_text' },
      { text: 'Model', value: 'model_text' },
      { text: 'Year', value: 'year' },
      { text: 'CYL', value: 'cylinders' },
      { text: 'Liters', value: 'engine_size_liters' },
      { text: 'MISC', value: 'misc' }
    ],
    staticPictureSet: []
  }),
  computed: {
    price () {
      return this.$store.getters['catalogStore/converterPrice']
    },
    vehicles () {
      return this.$store.getters['catalogStore/vehicles']
    }
  },
  methods: {
    /**
     * Get the total for a given key value
     * @param {String} key
     */
    totalAssayValue (key) {
      return this.assays.reduce((accu, assay) => {
        if (assay.hasOwnProperty(key)) accu += parseFloat(assay[key])
        return accu
      }, 0)
    },
    /**
     * Get the total for a given key value
     * @param {String} key
     */
    averageAssayValue (key) {
      if (!this.assays || this.assays.length < 1) return 0
      return this.totalAssayValue(key) / this.assays.length
    }
  },
  async mounted () {
    await this.$store.dispatch('catalogStore/fetchConverterPrice')
    await this.$store.dispatch('catalogStore/fetchVehicles')
    if (this.catalog.converter.static_picture_set) {
      const converterStaticImageGroupResponse = await axios.get(`/api/converter_image_groups/${this.catalog.converter.static_picture_set.id}`)
      this.staticPictureSet = converterStaticImageGroupResponse.data.images
    }
  }
}
</script>
