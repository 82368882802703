<!--A Simple area for updating market rates-->
<template>
  <v-card
    flat
    tile
  >
    <v-form
      v-model="valid"
      @submit.prevent="commitMarketRateChanges"
    >
      <v-card-text>
        <p>
          When the market rate is updated it is used as the rates for pricing catalytic converters.
          <br>
          The returnable rates should match the standard vendor return rate to improve pricing and profit estimation.
        </p>
        <p>
          Update the fields below then click <v-btn x-small>
            save rate changes
          </v-btn> to commit the changes
        </p>
        <v-row>
          <v-col>
            <v-text-field
              :rules="ppmRules"
              :label="'Platinum ' + ptLatest"
              v-model="pt"
              type="number"
            />
          </v-col>
          <v-col>
            <v-text-field
              :rules="ppmRules"
              :label="'Palladium ' + pdLatest"
              v-model="pd"
              type="number"
            />
          </v-col>
          <v-col>
            <v-text-field
              :rules="ppmRules"
              :label="'Rhodium ' + rhLatest"
              v-model="rh"
              type="number"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              :rules="returnRules"
              :label="'Platinum Return ' + ptReturnLatest"
              v-model="ptReturn"
              type="number"
            />
          </v-col>
          <v-col>
            <v-text-field
              :rules="returnRules"
              :label="'Palladium Return ' + pdReturnLatest"
              v-model="pdReturn"
              type="number"
            />
          </v-col>
          <v-col>
            <v-text-field
              :rules="returnRules"
              :label="'Rhodium Return ' + rhReturnLatest"
              v-model="rhReturn"
              type="number"
            />
          </v-col>
        </v-row>
        <div class="text-right">
          <p class="caption">
            {{ getLastUpdatedText }}
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          type="submit"
          color="success"
          text
          :disabled="(!changed || !valid)"
        >
          Save Rate Changes
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment'

export default {
  name: 'MarketRates',
  data: () => ({
    valid: false,
    loaded: false,
    createdLatest: null,
    modifiedBy: null,
    ptLatest: 0,
    pdLatest: 0,
    rhLatest: 0,
    ptReturnLatest: 0,
    pdReturnLatest: 0,
    rhReturnLatest: 0,
    pt: null,
    pd: null,
    rh: null,
    ptReturn: null,
    pdReturn: null,
    rhReturn: null,
    ppmRules: [
      value => !!value || 'Required',
      value => (value && !isNaN(value)) || 'Must be a Number',
      value => (value && value < 100000.00) || 'Exceeded Max Value',
      value => (value && value > 0) || 'Must be a Positive Number',
      value => (value && (String(value).split('.').length !== 2 || String(value).split('.')[1].length < 3)) || 'Max Decimals is 2'
    ],
    returnRules: [
      value => !!value || 'Required',
      value => (value && !isNaN(value)) || 'Must be a Number',
      value => (value && value <= 1.00) || 'Exceeded Max Value',
      value => (value && value > 0) || 'Must be a Positive Number',
      value => (value && (String(value).split('.').length !== 2 || String(value).split('.')[1].length < 3)) || 'Max Decimals is 2'
    ]
  }),
  computed: {
    getLastUpdatedText () {
      if (this.createdLatest == null) return ''
      return 'last updated ' + moment(this.createdLatest).format('MMMM Do YYYY h:mm:ss a') + ' by ' + this.modifiedBy
    },
    // returns false if all values match
    changed () {
      const compare = (x, y) => Number(x) !== Number(y)
      if (compare(this.ptReturnLatest, this.ptReturn)) return true
      if (compare(this.pdReturnLatest, this.pdReturn)) return true
      if (compare(this.rhReturnLatest, this.rhReturn)) return true

      if (compare(this.ptLatest, this.pt)) return true
      if (compare(this.pdLatest, this.pd)) return true
      return (compare(this.rhLatest, this.rh))
    }
  },
  methods: {
    // receives a api result object and sets the local market rate variables
    loadMarketRateData (result) {
      if (!result.data) return
      this.pt = this.ptLatest = result.data['pt_market']
      this.pd = this.pdLatest = result.data['pd_market']
      this.rh = this.rhLatest = result.data['rh_market']
      this.ptReturn = this.ptReturnLatest = result.data['pt_return_rate']
      this.pdReturn = this.pdReturnLatest = result.data['pd_return_rate']
      this.rhReturn = this.rhReturnLatest = result.data['rh_return_rate']
      this.createdLatest = result.data['created_at']
      this.modifiedBy = result.data['modified_by']
    },
    // Fetches the latest Market Rate
    fetchLatestMarketRate () {
      this.loaded = false
      axios.get('/api/market_rates/latest/')
        .then(this.loadMarketRateData)
        .finally(() => { this.loaded = true })
    },
    // Posts the new market rate data to the API
    commitMarketRateChanges () {
      axios.post('/api/market_rates/', {
        pt_market: this.pt,
        pd_market: this.pd,
        rh_market: this.rh,
        pt_return_rate: this.ptReturn,
        pd_return_rate: this.pdReturn,
        rh_return_rate: this.rhReturn
      }).then(this.loadMarketRateData)
    }
  },
  mounted () {
    if (!this.loaded) this.fetchLatestMarketRate()
  }
}
</script>
