<template>
  <v-dialog
    persistent
    width="600"
    :value="value"
    @input="$emit('input', false)"
  >
    <v-card>
      <v-card-title>
        Edit Catalog
      </v-card-title>
      <v-card-text>
        <p
          v-if="catalog.converter"
          class="warning--text"
        >
          Editing a catalog’s name and description is disabled when a converter is attached.
          Update the converter's code and notes instead to change these values.
        </p>
      </v-card-text>
      <v-form
        v-model="valid"
        @submit.prevent="updateCatalog"
      >
        <ValidationObserver
          v-slot="{ invalid }"
          ref="validationObserver"
        >
          <v-card-text>
            <ValidationProvider
              rules="required|max:110"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Name"
                v-model="name"
                :disabled="catalog.converter"
                :error-messages="errors"
                counter="110"
              />
            </ValidationProvider>
            <ValidationProvider
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                label="Unit of Measure"
                :items="unitsOfMeasureF"
                v-model="unitOfMeasure"
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider
              rules="max:256"
              v-slot="{ errors }"
            >
              <v-text-field
                label="description"
                :disabled="catalog.converter"
                v-model="description"
                :error-messages="errors"
                counter="256"
              />
            </ValidationProvider>
            <!--Should not change the type of CATALOG entry-->
            <!-- <ValidationProvider-->
            <!--   rules="required"-->
            <!--   v-slot="{ errors }"-->
            <!-- >-->
            <!--   <v-select-->
            <!--     label="Material Type"-->
            <!--     :items="catalogTypes"-->
            <!--     v-model="materialType"-->
            <!--     :error-messages="errors"-->
            <!--   />-->
            <!-- </ValidationProvider>-->
            <ValidationProvider
              v-if="catalog.material_type !== 'CV'"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Price"
                type="number"
                v-model="price"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-card-text>
        </ValidationObserver>
      </v-form>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="accent"
          text
          @click="$emit('input', false)"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          @click="updateCatalog"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CatalogEdit',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    catalog: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    valid: true,
    name: null,
    unitOfMeasure: null,
    description: null,
    // materialType: null,
    price: null
  }),
  watch: {
    catalog: {
      immediate: true,
      handler (catalog) {
        if (catalog) {
          this.name = catalog.name
          this.unitOfMeasure = catalog.unit_of_measure
          this.description = catalog.description
          this.price = catalog.price
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      'unitsOfMeasure': 'getUnitsOfMeasure',
      'catalogTypes': 'catalogStore/catalogTypes'
    }),
    unitsOfMeasureF () {
      if (this.materialType !== 'CV') return this.unitsOfMeasure
      return this.unitsOfMeasure.filter(uom => uom.value === 'PCS')
    }
  },
  methods: {
    updateCatalog () {
      if (this.materialType === 'CV') {
        this.price = 0
        this.unitOfMeasure = 'PCS'
      }
      const id = this.catalog.id
      const payload = {
        name: this.name,
        unit_of_measure: this.unitOfMeasure,
        description: this.description,
        // material_type: this.materialType,
        price: this.price
      }
      this.$store.dispatch('catalogStore/updateCatalog', { id, payload })
        .finally(() => {
          this.$emit('input', false)
        })
    }
  }
}
</script>
