var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"600","value":_vm.value},on:{"input":function($event){return _vm.$emit('input', false)}}},[_c('v-card',[_c('v-card-title',[_vm._v(" Edit Catalog ")]),_c('v-card-text',[(_vm.catalog.converter)?_c('p',{staticClass:"warning--text"},[_vm._v(" Editing a catalog’s name and description is disabled when a converter is attached. Update the converter's code and notes instead to change these values. ")]):_vm._e()]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.updateCatalog($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ValidationObserver',{ref:"validationObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-text',[_c('ValidationProvider',{attrs:{"rules":"required|max:110"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name","disabled":_vm.catalog.converter,"error-messages":errors,"counter":"110"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Unit of Measure","items":_vm.unitsOfMeasureF,"error-messages":errors},model:{value:(_vm.unitOfMeasure),callback:function ($$v) {_vm.unitOfMeasure=$$v},expression:"unitOfMeasure"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"max:256"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"description","disabled":_vm.catalog.converter,"error-messages":errors,"counter":"256"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}}],null,true)}),(_vm.catalog.material_type !== 'CV')?_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Price","type":"number","error-messages":errors},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})]}}],null,true)}):_vm._e()],1)]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.updateCatalog}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }